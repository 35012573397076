import * as React from "react"
import Bio from "../components/bio"
import { Flex, Divider,  ListItem, UnorderedList, Box, Icon, Spacer } from "@chakra-ui/react"
import Link from "./link"

const PostFooter = ({ previous, next }) => (
  <Box
    as="footer"
    mt={7}
    pt={3}
    mb={10}
  >
    <Divider />
    <Bio />
    {(previous || next) && (
      <Flex
        as={UnorderedList}
        flexDir={["column", "row"]}
        flexWrap
        listStyleType="none"
        px={0}
        pt={2}
        gap={2}
      >
        <ListItem
          mb={2}
          w={["100%", "40%"]}>
          {previous && (
            <Link to={previous.slug} rel="prev">
              ← {previous.title}
            </Link>
          )}
        </ListItem>
        <Spacer display={["hidden", "block"]}/>
        <ListItem
          mb={2}
          w={["100%", "40%" ]}>
          {next && (
            <Link to={next.slug} rel="next">
              {next.title} →
            </Link>
          )}
        </ListItem>
      </Flex>
    )}
  </Box>
)

export default PostFooter
