import React from "react"
import { Box } from "@chakra-ui/react"

function PostDate(props) {
  return (
    <>
      <Box as="p"
           mt={-3}
           mb={2}
           {...props} />
    </>
  )
}

export default PostDate
