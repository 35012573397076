/** @jsx jsx */
import { jsx } from "@emotion/react"
import { Disqus } from "gatsby-plugin-disqus"
import { MDXRenderer } from "gatsby-plugin-mdx"

import SEO from "../../components/seo"
import PostTitle from "../../components/post-title"
import PostHero from "../../components/post-hero"
import PostDate from "../../components/post-date"
import PostFooter from "../../components/post-footer"
import Layout from "../../components/layout"
import { getSrc } from "gatsby-plugin-image"
import PostTags from "../../components/post-tags"
import { Box, Divider } from "@chakra-ui/react"
import React from "react"

const Post = ({
  data: {
    site: {
      siteMetadata: { title },
    },
    blogPost: post,
    previous,
    next,
  },
  location,
}) => {

  // debugger
  console.log(post)
  return (
  <Layout location={location} title={title}>
    <SEO
      title={post.title}
      description={post.excerpt}
      imageSource={
        post.socialImage
          ? getSrc(post.socialImage)
          : getSrc(post.image)
      }
      imageAlt={post.imageAlt}
    />
    <main>
      <article>
        <header>
          <PostTitle>{post.title}</PostTitle>
          <PostDate>{post.date}</PostDate>
          <Box as={PostTags} tags={post.tags} />
          <PostHero post={post} />
        </header>
        <section>
          <MDXRenderer>{post.body}</MDXRenderer>
        </section>
      </article>
    </main>
    <PostFooter {...{ previous, next }} />
    <Disqus
      // mt={5}
      config={{
        url: `https://kajanm.com${location.pathname}`,
        identifier: post.id,
        title: post.title,
      }}
    />
  </Layout>
) }

export default Post
