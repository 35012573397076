import React from "react"
import { Heading } from "@chakra-ui/react"

function PostTitle(props) {
  return (
    <Heading {...props}
             size="xl"
             as="h1"
             mt="4"
             mb="4" />
  )
}

export default PostTitle
