import * as React from "react"
import { Box, Flex } from "@chakra-ui/react"
import ExternalLink from "./external-link"

const PostHeroCaption = ({ text, url }) => (
  <>
    {text && (
      <Flex>
        {url ? (
          <Box>Photo by <ExternalLink url={url} title={text} /></Box>
        ) : (
          <Box>{text}</Box>
        )}
      </Flex>
    )}
  </>
)

export default PostHeroCaption
